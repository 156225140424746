<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="机构">
          <mechanismSelect :mecid.sync="mechanism_id"></mechanismSelect>
        </el-form-item>
        <el-form-item label="用户">
          <userSelect :userid.sync="user_id"></userSelect>
        </el-form-item>
        <el-form-item label="查询时间">
            <el-date-picker
      v-model="date"
      type="daterange"
      align="right"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd">
    </el-date-picker>
        </el-form-item>
        <el-form-item label="结算状态">
          <el-select
            v-model="status"
            placeholder="请选择"
            style="width: 100px"
            @change="search"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
      <!-- 列表 -->
      <el-table
        :data="DataList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户昵称" prop="map.nickName"></el-table-column>
        <el-table-column label="用户电话" prop="map.mobile"></el-table-column>
        <el-table-column label="课程" prop="map.priceName"></el-table-column>
        <el-table-column label="结算金额" prop="cash"></el-table-column>
        <el-table-column label="下单时间" prop="update_time"></el-table-column>
        <el-table-column label="核销类型" prop="type"></el-table-column>
        <el-table-column label="结算状态">
          <template slot-scope="{ row }">
            <div v-if="row.is_settlement" style="color: green">已结算</div>
            <div v-else>未结算</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="Total"
      ></paging-fy>
  </div>
</template>
  
  <script>
import mechanismSelect from "@/components/select/mechanismSelect.vue";
import userSelect from "@/components/select/userSelect.vue";
export default {
  components: {
    mechanismSelect,
    userSelect,
  },
  data() {
    return {
      nav: { firstNav: "客服管理中心", secondNav: "核销记录" },
      DataList: [],
      is_loading: true,
      currentPage: 1,
      Total: 0,
      status: true,
      options: [
        {
          value: true,
          label: "已结算",
        },
        {
          value: false,
          label: "未结算",
        },
      ],
      user_id: "",
      mechanism_id: "",
      price: "",
      date:''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.is_loading = true;
      let url = "/user/userRecommenderIncomeLog/queryMechanismEarningList";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        is_settlement: this.status,
      };
      if (this.user_id) {
        params.user_id = this.user_id;
      }
      if (this.mechanism_id) {
        params.mechanism_id = this.mechanism_id;
      }
      if(this.date){
        params.start_time= this.date[0] + ' 00:00:00' 
        params.end_time= this.date[1] + ' 24:00:00'
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
  
  <style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  .el-form-item {
    width: 40%;
  }
}
</style>
  